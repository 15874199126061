import { toast } from "react-toastify"
import { API_SEVER,API_LOCAL_CMS, API_SERVER_GIGA, API_SERVER_GHN, API_SERVER_ERP, axiosIntance, API_LOCAL,   API_TRA_CUU,  API_LOCALIMAGE } from "./auth_header"
import axios from "axios"

export const _getBaoHanh = async (body) => {
  const request = await axiosIntance.post(`${API_LOCAL}/ApiGD_HangHoa/BaoHanhSerial`, body)
  return request.data
}


export const _kichHoatBaoHanh = async (body) => {
  const request = await axiosIntance.post(`${API_LOCAL}/ApiGD_HangHoa/UpdateNgayKichHoatBH`, body)
  return request.data
}
export const _getProductName = async (serial, manhomhang) => {
  const body = {
    "Serial": serial,
    "manhomhang": manhomhang
  }
  const request = await axiosIntance.post(`${API_LOCAL}/ApiGD_KichHoatBaoHanh/SearchKichHoatBaoHanhGiGaRobotic`, body)
  return request.data
}
export const _getProductNameKichHoat = async (serial, manhomhang) => {
  try{
  const body = {
    "Serial": serial,
    "manhomhang": manhomhang
  }
  const request = await axiosIntance.post(`${API_LOCAL}/ApiGD_KichHoatBaoHanh/SearchSerialKichHoatBaoHanh`, body)
  return request.data
}catch{
  return "";
}
}
export const _getTrungTamBaoHanh = async () => {
  return axiosIntance.get(`${API_LOCAL}/ApiGD_KichHoatBaoHanh/ListDanhSachTrungTamBaoHanh`)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      if (error.response) {
        return (error.response.data);
      }
    });

}
export const _getKichHoatBaoHanh = async (body) => {
  const request = await axiosIntance.post(`${API_LOCAL}/ApiGD_KichHoatBaoHanh/KichHoatBaoHanhGiGaRobotic`, body)
  return request.data
}


export const _guiYeuCauBaoHanh = async (body) => {
  const request = await axiosIntance.post(`${API_LOCAL}/Api_SaleNhanBaoHanh/KhachTaoDonBaoHanh`, body)
  return request.data
}

//api danh mục
export const _getCategory = async () => {
  const request = await axiosIntance.get(`${API_SERVER_GIGA}/product/categories`)
  return request.data;
}

export const _taoBaoHanhWeb = (body) => {
  return axiosIntance.post(`${API_LOCAL}/ApiGD_KichHoatBaoHanh/TaoBaoHanhWeb`, body, {
    headers: {
      'Content-Type': 'application/json',
      'token': 'bf900ccc-720c-11ed-9dc6-f64f768dbc22'
    }
  })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      if (error.response) {
        return (error.response.data);
      }
    });
};

export const _getYeuCauDetail = async (sochungtu) => {
  const body = {
    "sochungtu": sochungtu,
  }
  return axiosIntance.post(`${API_LOCAL}/ApiGD_KichHoatBaoHanh/GetDetalViewBaoHanhWeb`, body, {
    headers: {
      'Content-Type': 'application/json',
      'token': 'bf900ccc-720c-11ed-9dc6-f64f768dbc22'
    }
  })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      if (error.response) {
        return (error.response.data);
      }
    });
}


export const _getDemDanhSachPhieuBaoHanh = async (body) => {

  const request = await axios.post(`${API_LOCAL}/ApiGD_KichHoatBaoHanh/DemDanhSachPhieuBaoHanh`, body, {
    headers: {
      'Content-Type': 'application/json',
    }
  })
  if (request.data.statuscode == false) {
    toast.error(request.data.Message)
  } else {
    return request.data;
  }

}

export const _getDanhSachPhieuBaoHanh = async (body) => {

  const request = await axios.post(`${API_LOCAL}/ApiGD_KichHoatBaoHanh/GetDanhSachPhieuBaoHanh`, body, {
    headers: {
      'Content-Type': 'application/json',
    }
  })
  if (request.data.statuscode == false) {
    toast.error(request.data.Message)
  } else {
    return request.data;
  }


}

export const _getTenSanPham = async (mahang) => {
  const body = {
    "mahang": mahang,
    "username": "Datnt1"
  }
  const request = await axios.post(`${API_LOCAL}/ApiGD_KichHoatBaoHanh/TimHHBG_E1`, body, {
    headers: {
      'Content-Type': 'application/json',
    }
  })
  return request.data;
}



// Tinh - thanh pho
export const _getCity = async () => {
  const request = await axios.get(`${API_SERVER_GHN}/master-data/province`, {
    headers: {
      'Content-Type': 'application/json',
      'token': 'bf900ccc-720c-11ed-9dc6-f64f768dbc22'
    }
  })
  return request.data.data.reverse()
}

export const _getDistrict = async (id) => {
  const body = { 'province_id': id }
  const request = await axios.post(`${API_SERVER_GHN}/master-data/district`, body, {
    headers: {
      'Content-Type': 'application/json',
      'token': 'bf900ccc-720c-11ed-9dc6-f64f768dbc22'
    }
  })
  return request.data.data.reverse()
}



export const _getThuonghieu = async (id) => {
  const body = { 'manhomhang': id }
  const request = await axios.post(`${API_LOCAL}/ApiGD_KichHoatBaoHanh/ListMaNhomHang`, body, {
    headers: {
      'Content-Type': 'application/json',
    }
  })
  return request.data;
}

export const _getMaDaiLyBan = async (id) => {
  const body = { 'makhachhang': id }
  const request = await axios.post(`${API_LOCAL}/ApiGD_KichHoatBaoHanh/SearchMaKhachHangDaiLy`, body, {
    headers: {
      'Content-Type': 'application/json',
    }
  })
  return request.data;
}


export const _getWard = async (districtId) => {
  const body = { 'district_id': districtId }
  const request = await axios.post(`${API_SERVER_GHN}/master-data/ward`, body, {
    headers: {
      'Content-Type': 'application/json',
      'token': 'bf900ccc-720c-11ed-9dc6-f64f768dbc22'
    }
  })
  return request.data.data.reverse()
}

// Tim kiem san pham
export const _getProductErp = async (params) => {
  const request = await axiosIntance.get(`${API_SERVER_ERP}/BaoHanh/SearchSanPham`, {
    params: params
  })
  return request.data
}

// Check han bao hanh
export const _checkBaoHanh = async (params) => {
  const request = await axiosIntance.get(`${API_SERVER_ERP}/BaoHanh/CheckHanBaoHanh`, {
    params: params
  })
  return request.data
}

// Them bao hanh
export const _addBaoHanh = async (body) => {
  const request = await axiosIntance.post(`${API_SERVER_ERP}/BaoHanh/ThemTechBaohanh`, body)
  return request.data
}

// Tra cuu bao hanh
export const _traCuuBaoHanh = async (params) => {
  const request = await axiosIntance.get(`${API_TRA_CUU}/v1/BaoHanh/TraCuuSanPham`, {
    params: params
  })
  return request.data
}
export const _listImageTraCuu = async (params) => {
  const request = await axiosIntance.get(`${API_LOCAL}/ApiGD_KichHoatBaoHanh/ListFileDNBH?soChungTu=${params}`)
  return request.data
}
export const _uploadFile = async (sochungtu, params) => {
  try {
    const formData = new FormData();
    Object.entries(params).forEach(([key, value]) => {
      formData.append(key, value);
    });
    const response = await fetch(`${API_LOCALIMAGE}/UploadFile/UpFile?RootFolder=DNBH&Folder=${sochungtu}`, {
      method: 'POST',
      body: formData,
    });
    if (!response.ok) {
      toast.error("Vui lòng chọn lại định dạng")
    } else {
      const data = await response.json(); // assuming the response is JSON 
      return data;
    }
  } catch (error) {
    return error;
  }
}
export const _UpdateImageBaoHanh = async (sochungtu, filename) => {
  try {
    const params = {
      SoChungTu: sochungtu,
      FilesName: [filename]
    }

    const response = await fetch(`${API_LOCAL}/ApiGD_KichHoatBaoHanh/CapNhatThongTinBaoHanh`, {
      method: 'POST',
      body: JSON.stringify(params),
      headers: {
        'Content-Type': 'application/json'
      },
    });
    if (!response.ok) {
      toast.error(toast.Message)
    } else {

    }
    return response;
  } catch (error) {
    return error;
  }
}

export const _doimatkhau = async (body) => {
  try {
    const request = await axiosIntance.post(`${API_LOCAL}/ApiGD_KichHoatBaoHanh/DoiMaSo`, body)
    if (request.data.statuscode == false) {
      toast.error(request.data.Message)
    } else {
      return request.data;
    }
  } catch (error) {
    return error;
  }
}

export const _addFeedBack = async (body) => {
  try {
    const request = await axiosIntance.post(`${API_LOCAL}/ApiGD_KichHoatBaoHanh/ThemGhiChuTuKhachHang`, body)
    if (request.data.statuscode == false) {
      toast.error(request.data.Message)
    } else {
      toast.success("Cập nhật phản hồi thành công")
      return request.data;
    }
  } catch (error) {
    return error;
  }
}


export const _danhSachSanPham = async (id) => {
  const request = await axiosIntance.get(
    `${API_SERVER_GIGA}/product/categories-detail/${id}`
  );
  return request.data;
};



export const _danhSachMaPhuKien = async (body) => {
  try {
    const request = await axiosIntance.post(`${API_LOCAL}/CMSGigaCare/ListMaPhuKienByClient`, body)
    if (request.data.statuscode == false) {
      toast.error(request.data.Message)
    } else {
    
      return request.data;
    }
  } catch (error) {
    return error;
  }
};

export const _danhSachThuongHieu = async () => {
  const request = await axiosIntance.get(
    `${API_LOCAL}/CMSGigaCare/ListThuongHieuByClient
`,
  );
  return request.data;
};

export const _danhSachLoaiPhuKien = async (thuonghieu) => {
  const request = await axiosIntance.get(
    `${API_LOCAL}/CMSGigaCare/ListLoaiPhuKien?thuongHieu=${thuonghieu}`,
  );
  return request.data;
};

export const _danhSachDongSanPham = async (thuonghieu) => {
  const request = await axiosIntance.get(
    `${API_LOCAL}/CMSGigaCare/ListDongSanPham?thuongHieu=${thuonghieu}`,
    
  );
  return request.data;
};

