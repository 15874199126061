import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { _danhSachDongSanPham, _danhSachLoaiPhuKien, _danhSachMaPhuKien, _danhSachThuongHieu } from "../../api/baoHanh"
import BannerImage from "../../assets/image/banner-phukien.jpg";
import Search from "antd/es/input/Search"
import Paginations from "../layout/pagination";
import { formatCurrency } from "../../helper/formatNumber";
import { setLoading } from "../../redux/loading/action";
import { Modal } from "antd";
const PhuKien = () => {

  const dispatch = useDispatch()
  const [selectedIndex, setSelectedIndex] = useState([]);
  const [selectedIndexThuongHieu, setselectedIndexThuongHieu] = useState([]);
  const [selectedIndexSPTT, setselectedIndexSPTT] = useState([]);

  
  
  const danhSachSanPhamEcovacs = useSelector(store => store.categories.danhSachSanPhamEcovacs)
  const getLoaiPhuKienEcovacs = useSelector(store => store.categories.danhSachPhuKien)
  const danhSachThuongHieu = useSelector(store => store.categories.danhSachThuongHieu)
  const danhSachDSP = useSelector(store => store.categories.danhSachDSP)
  const danhSachMaPhuKien = useSelector(store => store.categories.danhSachMaPhuKien)

 const [body, setBody] = useState({ }); 
  const [danhSachPhuKienEcovacs, setDanhSachPhuKienEcovacs] = useState([])
  const [danhSachLoaiPhuKienEcovacs, setDanhSachLoaiPhuKienEcovacs] = useState([])
  const [danhSachDongSanPhamEcovacs, setDanhSachDongSanPhamEcovacs] = useState([])
  const [danhSachThuongHieuEcovacs, setDanhSachThuongHieuEcovacs] = useState([])

  const [isThuongHieu,setIsThuongHieu] = useState(true);

  const [thuonghieu, setThuongHieu] = useState([])
  const [loaiphukien, setLoaiPhuKien] = useState([])
 
  const [IdSanPhamTuongThich , setIdSanPhamTuongThich ] = useState([])

  const [sptt, setSPTT] = useState([])
  
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(12)
  const [totalProducts, setTotalProducts] = useState(0)
  const [isModalFile, setisModalFile] = useState(false);

  const handleClick = async(index) => {

    setSelectedIndex((prevSelected) => {
      if (prevSelected.includes(index)) {
        setLoaiPhuKien(selectedIndex.filter((i) => i !== index))
        const data=  {
          ...body,
          IdLoaiPhuKien: selectedIndex.filter((i) => i !== index)
        }
        setBody({
          ...body,
          IdLoaiPhuKien: selectedIndex.filter((i) => i !== index)
        })
         searchMaPhuKien(data)
        return prevSelected.filter((i) => i !== index);
      } else {
        setLoaiPhuKien([...selectedIndex, index])
        const data=  {
          ...body,
          IdLoaiPhuKien: [...selectedIndex, index]
        }
        setBody({
          ...body,
          IdLoaiPhuKien: [...selectedIndex, index]
        })
         searchMaPhuKien(data)
        return [...prevSelected, index];
      }
    });
  };

  const [categoryId, setCategoryId] = useState("");
 
  useEffect(() => {
    if (categoryId) {
      dispatch(danhSachThuongHieu(categoryId));
    }
  }, [categoryId]);

  useEffect(() => {
    const onLoadData = async() => {
      await dispatch(setLoading(true));
      try {
        const [danhSachPhuKien, danhSachThuongHieu] = await Promise.all([
          _danhSachMaPhuKien(body),
          _danhSachThuongHieu(),
        ]); 
        setDanhSachPhuKienEcovacs(danhSachPhuKien);
        setDanhSachThuongHieuEcovacs(danhSachThuongHieu);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        await dispatch(setLoading(false));
      }
    }
    onLoadData();
  }, [])
  const getThuongHieu = async (thuonghieu) => {
    setselectedIndexThuongHieu([thuonghieu])
    setThuongHieu(thuonghieu)
    setSelectedIndex([])
    setLoaiPhuKien([])
    setSPTT('')
    const data = {
      IdThuongHieu: thuonghieu
    }
    await searchMaPhuKien(data)
    setBody(data)
    _danhSachLoaiPhuKien(thuonghieu)
    .then(res => {
      setDanhSachLoaiPhuKienEcovacs(res)
    })
    .catch(error => {
      console.error('Error fetching data:', error)
    })
    _danhSachDongSanPham(thuonghieu).then(res => {
      setDanhSachDongSanPhamEcovacs(res)
    })
    setIsThuongHieu(false)
  }
 const getLoaiPhuKien = async(thuonghieu)  => {
  setThuongHieu(thuonghieu)
 
  const data = {
    ...body,
    IdThuongHieu: thuonghieu
  }
  await searchMaPhuKien(data)
  setBody(data)
  _danhSachLoaiPhuKien(thuonghieu)
  .then(res => {
    setDanhSachLoaiPhuKienEcovacs(res)
  })
  .catch(error => {
    console.error('Error fetching data:', error)
  })
  _danhSachDongSanPham(thuonghieu).then(res => {
    setDanhSachDongSanPhamEcovacs(res)
  })
  setIsThuongHieu(false)
 }

 const onMouseEnter = (id) => {
  setCategoryId(id);
};

const searchSanPham = async(id,name ) => {
  setselectedIndexSPTT([id])
setIdSanPhamTuongThich(id)
  const data = {
    ...body,
    idSanPhamTuongThich: id
  }
  setBody(data);
  setSPTT(name);
  await searchMaPhuKien(data)
}

console.log(selectedIndexThuongHieu)
const searchMaPhuKien = (data) => {
  _danhSachMaPhuKien(data)
  .then(res => {
    setDanhSachPhuKienEcovacs(res)
  })
  .catch(error => {
  
  })
}
 const showModalFile = () => {
    setisModalFile(true);
  };
  const handleOk = () => {
    setisModalFile(false);
  };
  const handleCancel = () => {
    setisModalFile(false);
  };

const onSearch = async (value, _e) => {
  const data = {
    tenHang: value,
    
    ...(thuonghieu.length > 0 && { IdThuongHieu: thuonghieu }),
    ...(IdSanPhamTuongThich.length > 0 && { idSanPhamTuongThich: IdSanPhamTuongThich }),
    ...(loaiphukien.length > 0 && { IdLoaiPhuKien: loaiphukien }),
  }
  _danhSachMaPhuKien(data)
  .then(res => {
    setDanhSachPhuKienEcovacs(res)
  })
  .catch(error => {
    console.error('Error fetching data:', error)
  })
};
  console.log(selectedIndexSPTT)
  return (
    <div className="product-selector">
        <div className="banner-image position-relative">
        <figure className="image img-fluid ">
          <img src={BannerImage} className="w-100" />
        </figure>
      </div>
      <div className="container">    
      <div class=" card mt-3">  
      <div className=" filter_product m-3">
      <div className="list-search-category list-thuonghieu" style={{display:'flex'}}>
      <div className="text-primary" style={{marginRight:'31px'}}>Bộ lọc tìm kiếm:</div>
      Thương hiệu&nbsp;<span>:<span className="text-primary"> {thuonghieu} </span></span>&nbsp;
        <i className="fa fa-caret-down" />
        <div className="row">
        <div
          className="w-100 mega-thuonghieu p-3 rounded col-md-12 phukien_filter"
        >
            {danhSachThuongHieuEcovacs &&
              danhSachThuongHieuEcovacs.filter(x => x.CmsSttHienWeb === true) 
                .map((item, index) => (
                  <div
                    key={index}
                    style={{width:'100%'}}
                    className="col-md-2 tab_thuonghieu"  
                  >
                      <div onClick={() => getThuongHieu(item.MaNhomHangChiTiet) }  key={index} className="sub-label fw-bold cursor-pointer text-primary tab_thuonghieu img">
                      <img src={item.CmsImageLink}/></div>
                      </div>
                ))}
          </div>
          </div>
      </div>
      <div className="list-category" tabindex="0"  onMouseEnter={() => onMouseEnter()}>
      Sản phẩm tương thích&nbsp;<span>: <span className="text-primary">{sptt}</span></span>&nbsp;
        <i className="fa fa-caret-down" /> 
        <div
          className="w-100  mega-category p-3 rounded"
        >
          <div className="row">
            {danhSachDongSanPhamEcovacs &&
              danhSachDongSanPhamEcovacs.sort((a, b) => a.SttHienThi - b.SttHienThi) 
                .map((item, index) => (
                  <div
                    key={index}
                    className={
                      item.length > 0
                        ? "col-md-3 list"
                        : "col-md-3 only"
                    }
                  >
                    <div
                      className="col-md-12 li"
                    >
                      <div className="sub-label fw-bold cursor-pointer text-primary">
                        {item.TenDongSanPham}
                      </div>
                      </div>
                      <div className="row" key={index}>
                      {item.SanPhamTuongThich &&
                        item.SanPhamTuongThich.length > 0 &&
                        item.SanPhamTuongThich.sort((a, b) => a.STT_HIEN_THI - b.STT_HIEN_THI).map((i, e) => (
                          <div
                            className="sub-child-label cursor-pointer"
                            onClick={() => searchSanPham(i.ID , i.TEN_SAN_PHAM_TUONG_THICH)}
                            key={e}
                          >
                            {i.TEN_SAN_PHAM_TUONG_THICH}
                          </div>
                        ))}
                    </div>
                  </div>
                ))}
          </div>
        </div>
             </div>  
      <div className="list-search-category" style={{width:'40%'}}>
        <Search 
      placeholder="Gõ từ khóa muốn tìm "
      enterButton="Tìm kiếm"
      size="large"
      suffix={""}
      onSearch={onSearch}
    />
      </div>

       </div>      
    </div>

 <div class="card_mobile mt-3"  >  
    <div onClick={() => showModalFile()} className="filter-wrap" style={{marginRight:'31px'}}>
    <svg className="icon icon-filter" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M14.5 1H1.5V3.47059L6.375 8.41176V15L9.625 12.9412V8.41176L14.5 3.47059V1Z" stroke="#111111" stroke-width="1.5" stroke-linejoin="round"></path>
			</svg>
      Bộ lọc:</div> 
      <div className="list-search-categorys">
        <Search
      placeholder="Gõ từ khóa muốn tìm "
      enterButton="Tìm kiếm"
      size="large"
      suffix={""}
    
      onSearch={onSearch}
    />
      </div>  
     
    </div> 
    {isThuongHieu && (
      <div className="res_pc">
      <div className="text-center my-4 fw-bold text-primary " style={{fontSize:'21px'}}>Bạn đang tìm kiếm phụ kiện của thương hiệu nào?</div> 
    <div className="col-md-12 mt-3 phukien_filter">
    {danhSachThuongHieuEcovacs?.filter(x => x.CmsSttHienWeb == true)?.map((item, index) => (
  <div onClick={() =>getLoaiPhuKien(item.MaNhomHangChiTiet)} key={index} className="col-md-2 tab_thuonghieu img">  <img src={item.CmsImageLink} /></div>
))}
      </div> 
      </div>
      )}
      <div className="col-md-12 mt-3 phukien_filter res_pc" >
      {danhSachLoaiPhuKienEcovacs?.map((item, index) => (
  <div key={index}   onClick={() => handleClick(item.ID)}   className={`col-md-2 tab_product ${selectedIndex.includes(item.ID) ? 'selected' : ''}`}>{item.TEN_LOAI_PHU_KIEN}</div>
))}
       
      </div> 
        <div className="row mt-4">
          <div className="col-md-12">
            <div className="product-item-selector">
              {danhSachPhuKienEcovacs?.length > 0 ?
                (danhSachPhuKienEcovacs?.map((item, index) => (
                  <div key={index} className="list-item-selector">
                    <div className="card-product-selector">
                      <div className='d-item d-inline-block'>
                        <img src={item.AnhHienThi} className='w-100 img-product-selector' loading='lazy' alt={item.thumb_ecovacs} />
                      </div>
                      <div className='info-product p-2'>
                        <div className="info-detail">
                          <h5 className='mb-2'>
                          <div className="require">{formatCurrency(item.GiaBan)}đ</div>
                   
                            <div className="text-primary ma_chuan my-2" style={{fontSize:'18px'}}>  {item.MaChuan}</div>
                     
                            <div className="ten_hang_hien_thi my-2">
                              {item.TenHangHienThi}
                            </div>
                          </h5>
                       
                        </div>
                        <div className="box-detail">
                          <a target="_blank" key={item.Id} className='item-news' href={item.LinkGiga}>
                            <p className="text-detail">Xem chi tiết </p>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
                ) : (
                  <h6>Không có phụ kiện</h6>
                )}

            </div>
          
            <div className='w-100 my-3'>
              
              <Paginations page={page} setPage={setPage} perPage={perPage} totalItem={totalProducts} />
            </div>
          </div>
        </div>
      </div>
      <Modal
            width={1800}
            style={{ zIndex: "99" }}
            title="Bộ lọc tìm kiếm"
            open={isModalFile}
            okText="Tra cứu"
            onOk={handleOk}
            onCancel={handleCancel}
          >
            
            <div className="list-filter--main">
  <div>
    <div className="filter-group--block">
      <div className="filter-group--title jsTitle">
        <span>Thương hiệu</span>
      </div>
      <div className="filter-group--content">
      {danhSachThuongHieuEcovacs &&
              danhSachThuongHieuEcovacs.filter(x => x.CmsSttHienWeb == true) 
                .map((item, index) => (
                  <>
                  
                  <div
                    key={index}
                    
                  >
                      <label onClick={() =>getThuongHieu(item.MaNhomHangChiTiet)} className={`sub-label fw-bold cursor-pointer text-primary tab_thuonghieu img ${selectedIndexThuongHieu.includes(item.MaNhomHangChiTiet) ? 'selected' : ''}`}   key={index}>
                      <img src={item.CmsImageLink} /></label>
                      </div>
                      </>
                ))}
          
      </div>
      <br/>
      <div className="line"></div>
      <div className="filter-group--title jsTitle">
        <span>Sản phẩm tương thích</span>
      </div>
      <div className="filter-group--contents">
      {danhSachDongSanPhamEcovacs &&
              danhSachDongSanPhamEcovacs.sort((a, b) => a.STT_HIEN_THI - b.STT_HIEN_THI) 
                .map((item, index) => (
                  <div
                    key={index}
                    className={
                      item.length > 0
                        ? "col-md-3 list"
                        : "col-md-3 only"
                    }
                  >
                    <div
                      className="col-md-12 li"
                    >
                      <div className="sub-label fw-bold cursor-pointer text-primary">
                        {item.TenDongSanPham}
                      </div>
                      </div>
                      <div className="row" key={index}>
                      {item.SanPhamTuongThich &&
                        item.SanPhamTuongThich.length > 0 &&
                        item.SanPhamTuongThich.sort((a, b) => a.STT_HIEN_THI - b.STT_HIEN_THI).map((i, e) => (
                          <div
                            className={`sub-child-label cursor-pointer tab_sptt img ${selectedIndexSPTT.includes(i.ID) ? 'selected' : ''}`}
                            onClick={() => searchSanPham(i.ID , i.TEN_SAN_PHAM_TUONG_THICH)}
                            key={e}
                          >
                            {i.TEN_SAN_PHAM_TUONG_THICH}
                          </div>
                        ))}
                    </div>
                  </div>
                ))}
          
      </div>


      <br/>
      <div className="line"></div>
      <div className="filter-group--title jsTitle">
        <span>Loại phụ kiện</span>
      </div>
                <div className="filter-group--title jsTitle">

                <div className="filter-group--contents">
      {danhSachLoaiPhuKienEcovacs?.map((item, index) => (
  <div key={index}   onClick={() => handleClick(item.ID)}   className={`col-md-2 tab_product ${selectedIndex.includes(item.ID) ? 'selected' : ''}`}>{item.TEN_LOAI_PHU_KIEN}</div>
))}
       </div>
     
      </div>
    </div>
  </div>
 
   
</div>

         
            
             </Modal>
    </div>
    
  )
}

export default PhuKien

