import React from "react"
import { useParams, useLocation } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faThumbTack, faThumbsUp } from "@fortawesome/free-solid-svg-icons"
import { ReactComponent as Logo } from '../../assets/image/thumbtack-solid.svg'

const PhieuBaoHanh = () => {

  const { id } = useParams()
  const location = useLocation()

  const dichVu = location.state && location.state.dichVu

  return (
    <div className="phieu-bao-hanh">
      <div className="container">
        <div className="data-content p-4">
          <div className="title-content text-center mb-3">
            <h3 className="text-uppercase fw-bold">Đăng ký trực tuyến</h3>
            <h6 className="fw-bold">Dịch vụ bảo hành, sửa chữa, chăm sóc tư vấn sản phẩm</h6>
          </div>
          <div className="stepper-wrapper">
            <div className="stepper-item completed">
              <p className="step-counter"><i className="fa fa-check text-white"></i></p>
              <p className="step-name fw-bold">Bước 1: Đăng ký</p>
            </div>
            <div className="stepper-item completed">
              <p className="step-counter"></p>
              <p className="step-name fw-bold">Bước 2: Xử lý đơn</p>
            </div>
            <div className="stepper-item ">
              <p className="step-counter"></p>
              <p className="step-name fw-bold">Bước 3: Hoàn tất</p>
            </div>
          </div>
          <fieldset>
            <div className="title-header">
              <p>Quý khách đã tạo phiếu dịch vụ thành công!</p>
              <p>Mã số phiếu: <h6 className="text-red d-inline-block fw-bold">{id}</h6></p>
            </div>
            {
              dichVu !== 'Hướng dẫn tư vấn online' && (
                <div>
                  <div className="title-content mt-3">
                    <h6 className="text-primary fw-bold">Quý khách vui lòng gửi sản phẩm về Trung tâm bảo hành của GIGACARE theo một trong các địa chỉ sau:</h6>
                    <div className="title-1 mt-3">
                      <h6 className="fw-bold">    <i class="fa fa-neuter"></i> Trung tâm bảo hành số 1</h6>
                      <p>Địa chỉ: <span>Kho G1 946 Bạch Đằng, Phường Thanh Lương, Quận Hai Bà Trưng, Hà Nội</span></p>
                      <p>Số điện thoại: <span>0988.879.921</span></p>
                    </div>
                    <div className="title-2 mt-3">
                      <h6 className="fw-bold">    <i class="fa fa-neuter" ></i> Trung tâm bảo hành số 2</h6>
                      <p>Địa chỉ: <span>55 Minh Phụng, P. Tân Thới Nhất, Quận 12, TP. Hồ Chí Minh (Cạnh chung cư Prosper Plaza)</span></p>
                      <p>Số điện thoại: <span>0901.807.233</span></p>
                    </div>
                    <div className="title-3 mt-3">
                      <h6 className="fw-bold">  <i class="fa fa-neuter"></i> Trung tâm bảo hành số 3</h6>
                      <p>Địa chỉ: <span>35 Chu Mạnh Trinh, Phường Khuê Trung, Quận Cẩm Lệ, TP. Đà Nẵng</span></p>
                      <p>Số điện thoại: <span>0931.114.707</span></p>
                    </div>
                  </div>
                  <div className="title-end mt-3">
                    <h6 className="text-primary fw-bold">Xin quý khách lưu ý:</h6>
                    <p><Logo fill='#d9524e ' width={25} /><span className="ms-2">Trên bưu phẩm gửi về, Quý khách vui lòng ghi rõ Số điện thoại liên hệ và mã số dịch vụ <h6 className="text-red d-inline-block fw-bold">{id}</h6> để được phục vụ nhanh chóng nhất.</span></p>
                    <p><Logo fill='#d9524e ' width={25} /><span className="ms-2">Quý khách vui long lưu lại mã vận đơn chuyển phát nhanh để thuận tiện tra cứu quá trình vận chuyển sản phẩm tới Trung tâm bảo hành</span></p>
                    <p><Logo fill='#d9524e ' width={25} /><span className="ms-2">Quý khách có thể tra cứu tình trạng phiếu dịch vụ của mình <a className="text-primary" href="/tra-cuu-bao-hanh">tại đây</a></span></p>
                  </div>
                </div>
              )
            }
              <p className="mt-3">Mọi thắc mắc vui lòng liên hệ Hotline chăm sóc khách hàng: 1900 633 870 (08-21h hằng ngày).</p>
              <p>Xin cảm ơn quý khách!</p>
          </fieldset>
        </div>

      </div>

    </div>
  )
}

export default PhieuBaoHanh