import React, { useEffect, useState } from 'react'

import { Table } from 'reactstrap'
import ScrollAnimation from 'react-animate-on-scroll'
import { _listImageTraCuu, _traCuuBaoHanh } from '../../api/baoHanh'
import moment from 'moment'
import { SearchOutlined, PictureOutlined, EditOutlined, BellOutlined } from "@ant-design/icons";
import { toast } from 'react-toastify'
import { Parser } from 'html-to-react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import {  Modal } from 'antd';

import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { AddFeedback, UploadFile, updateImageBaoHanh } from '../../redux/category/action'
import { setLoading } from '../../redux/loading/action'
import { useParams } from 'react-router-dom/cjs/react-router-dom'
import { getTypeFile } from '../../helper/getTypeFile'
const TraCuuDetail = () => {
  const form = useForm();
  const history = useHistory();
  const [serial, setSerial] = useState('')
  const [socuoiSdt, setSocuoiSdt] = useState('')
  const [isModalOpenVideo, setisModalOpenVideo] = useState(false);
  const [isModalOpenFeedback, setisModalOpenFeedback] = useState(false);
  const [isModalOpenViewFeedback, setisModalOpenViewFeedback] = useState(false);

  const [ttBaoHanh, setTtBaoHanh] = useState(null)
  const addFeedback = useSelector((store) => store.categories.AddFeedback)
  const [listFileVideo, setListFleVideo] = useState([]);
  const [listDetailFileVideo, setListDetailFileVideo] = useState("");
  const [feedback, setFeedBack] = useState("")
  const completedSteps = ['Tạo yêu cầu', 'TTBH tiếp nhận', 'TTBH xử lý', 'Hoàn trả sản phẩm', 'Hoàn tất'];
  const dispatch = useDispatch();
  const [isModalOpenDetailVideo, setisModalOpenDetailVideo] = useState(false);
  const [isModalOpenZoomInVideo, setisModalOpenZoomInVideo] = useState(false);
  const { sdt, sochungtu } = useParams();
  const [listImage, setListImage] = useState([]);
  const [image, setImage] = useState('');
  useEffect(() => {
    setListDetailFileVideo(Array.from(listFileVideo)?.map(file => ({
      name: file.name,
      size: (file.size / 1024).toFixed(2)
    })));

  }, [listFileVideo]);
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0); // Đưa trang lên đầu ngay lập tức
    }, 0);
  }, []);
  useEffect(() => {
    if (sdt && sochungtu) {

      onLoaddata();
    }
    getlistImage();
  }, []);
  const onLoaddata = async () => {
    await dispatch(setLoading(true));
    const params = {
      searchText: sochungtu,
      SocuoiSdt: sdt.slice(-4)
    }



    await _traCuuBaoHanh(params)
      .then(res => {

        setTtBaoHanh(res)

        if (res.ghiChuToiKhachHang) {
          setisModalOpenViewFeedback(true);
        }
      })
      .catch(errors => {
        toast.info(errors?.response?.data)
      })


    await dispatch(setLoading(false));
  }


  const getlistImage = () => {
    _listImageTraCuu(sochungtu)
      .then(res => {
        setListImage(res)
      })
      .catch(errors => {
        toast.info(errors?.response?.data)
      })
  }

  const buocXuLyDonTienTrinh = ttBaoHanh?.buocXuLyDon?.map(item => item.tienTrinh);
  const navigateToYeuCauDetail = (id) => {
    history.push(`/gui-yeu-cau/${id}`);
  }
  const showModalVideo = () => {
    setisModalOpenVideo(true);
  }
  const handleOkVideo = async () => {
    try {
      for (let i = 0; i < listFileVideo.length; i++) {
        const file = listFileVideo[i];
        const responseimage = await dispatch(UploadFile(sochungtu, {
          files: file,
        }));
        const resultUpdate = await dispatch(updateImageBaoHanh(sochungtu, responseimage[0].linkFile));
      }
      toast.success("Up file thành công")
    } catch (e) {
      toast.error("Vui lòng chọn lại tệp tin");
    }
    setisModalOpenVideo(false);
  };
  const handleCancelVideo = () => {
    setisModalOpenVideo(false);
  };
  const showModalFeedback = () => {
    setisModalOpenFeedback(true);
  }
  const handleOkFeedback = async () => {
    await dispatch(setLoading(true));
    const body = {
      "sochungtu": serial,
      "ghichu": feedback
    }
    await dispatch(AddFeedback(body))
    await dispatch(setLoading(false));
    setisModalOpenFeedback(false);
  };
  const handleCancelFeedback = () => {
    setisModalOpenFeedback(false);
  };
  const showModalViewFeedback = () => {
    setisModalOpenViewFeedback(true);
  }
  const handleOkViewFeedback = async () => {

    setisModalOpenViewFeedback(false);
  };
  const handleCancelViewFeedback = () => {
    setisModalOpenViewFeedback(false);
  };

  const onChangeNhapPhanHoi = (e) => {
    setFeedBack(e.target.value)
  }

  const onChangeFile = async (e) => {
    const selectedFile = e.target.files;
    setListFleVideo(selectedFile);


  };

  const showModalDetailVideo = () => {
    setisModalOpenDetailVideo(true);
  }
  const handleOkDetailVideo = () => {
    setisModalOpenDetailVideo(false);
  };

  const handleCancelDetailVideo = () => {
    setisModalOpenDetailVideo(false);
  };
  const openModalZoomInImage = (urlimage) => {
    setisModalOpenZoomInVideo(true);
    setImage(urlimage);
  }
  const handleOkZoomInVideo = () => {
    setisModalOpenZoomInVideo(false);
  }
  const handleCancelZoomInVideo = () => {
    setisModalOpenZoomInVideo(false)
  }

  return (

    <div className="container mt-6">
      <div className='tra-cuu'>
        <div className="content h-100 align-middle ">
          <div className="title-content text-center mb-3">
            <h3 className="text-uppercase fw-bold">Tra cứu phiếu yêu cầu bảo hành</h3>
            <h6 className="fw-bold">Dịch vụ bảo hành, sửa chữa, chăm sóc tư vấn sản phẩm</h6>
          </div>
          <div className='row'>
            <div className='col-md-12'>
              {
                ttBaoHanh &&
                <div>
                  <ScrollAnimation
                    animateIn="fadeInDown"
                    animateOut='fadeInDown' >
                    <h6 className='d-block text-center mt-3'>

                    </h6>
                  </ScrollAnimation>
                  <div style={{ position: 'relative' }}>
                    <fieldset>
                      <div className='col-md-12'>
                        <div className='row'>
                          <div className='col-md-6'>
                            <div className="form-group row">
                              <label className="col-form-label text-primary fw-bold col-md-4">Mã yêu cầu:</label>
                              <label className="col-form-label fw-bold col-md-8">{ttBaoHanh.maPhieuDNBH}</label>
                            </div>
                            <div className="form-group row">
                              <label className="col-form-label text-primary fw-bold col-md-4">Tên sản phẩm:</label>
                              <label className="col-form-label col-md-8">{ttBaoHanh.tenSanPham}</label>
                            </div>
                            <div className="form-group row">
                              <label className="col-form-label text-primary fw-bold col-md-4">Dịch vụ:</label>
                              <label className="col-form-label col-md-8 fw-bold">{ttBaoHanh.dichVu}</label>
                            </div>
                            <div className="form-group row">
                              <label className="col-form-label text-primary fw-bold col-md-4">Trạng thái:</label>
                              <label className="col-form-label col-md-8 text-red fw-bold">{ttBaoHanh.trangThai}</label>
                            </div>
                          </div>
                          <div className='col-md-6'>
                            <div className="form-group row">
                              <label className="col-form-label text-primary fw-bold col-md-4">Số điện thoại:</label>
                              <label className="col-form-label col-md-8">{ttBaoHanh.soDienThoaiLienHe}</label>
                            </div>
                            <div className="form-group row">
                              <label className="col-form-label text-primary fw-bold col-md-4">Serial Number:</label>
                              <label className="col-form-label col-md-8">{ttBaoHanh.soSerial}</label>
                            </div>
                            <div className="form-group row">
                              <label className="col-form-label text-primary fw-bold col-md-4">Hạn bảo hành:</label>
                              <label className="col-form-label col-md-8">{ttBaoHanh.ngayHetHanBaoHanh}</label>
                            </div>
                            <div className="form-group row">
                              <label className="col-form-label text-primary fw-bold col-md-4">Phí sử dụng dịch vụ:</label>
                              <label className="col-form-label fw-bold col-md-8">{ttBaoHanh.phiSuDungDichVu}</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                    <br />
                    <div className='icon_tracuu'>
                      <div style={{ marginBottom: '20px', cursor: 'pointer' }} target='_blank' onClick={() => navigateToYeuCauDetail(ttBaoHanh?.maPhieuDNBH)}> <SearchOutlined style={{ fontSize: '20px', marginRight: '5px' }} /> <span className='title_important text-primary'> Xem phiếu yêu cầu</span></div>
                      <div style={{ marginBottom: '20px', cursor: 'pointer' }} onClick={showModalVideo} target='_blank'><svg width='20' height='20' aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                      </svg> <span className='title_important text-primary'>Gửi hình ảnh,video</span></div>
                      <div style={{ marginBottom: '20px', cursor: 'pointer' }} onClick={showModalDetailVideo}> <PictureOutlined style={{ fontSize: '20px', marginRight: '5px' }} />  <span className='title_important text-primary '>Xem hình ảnh,video</span></div>
                      <div style={{ marginBottom: '20px', cursor: 'pointer' }} target='_blank' onClick={showModalFeedback} > <EditOutlined style={{ fontSize: '20px', marginRight: '5px' }} /><span className='title_important text-primary'> Thêm ghi chú </span></div>
                      <div style={{ cursor: 'pointer' }} target='_blank' onClick={showModalViewFeedback} >  <span className='anticon-bells'><BellOutlined style={{ fontSize: '20px', marginRight: '5px' }} />{ttBaoHanh.ghiChuToiKhachHang && (<span className='bell_notification'></span>)}</span><span className='title_important text-primary'> Xem thông báo </span></div>
                    </div>
                  </div>
                  <div className="stepper-wrapper">

                    {completedSteps?.map((item, index) => (
                      <div
                        key={index}
                        className={`stepper-item ${buocXuLyDonTienTrinh.includes(item) ? "completed" : ""}`}
                      >
                        <p className="step-counter">
                          {buocXuLyDonTienTrinh.includes(item) && (<i className="fa fa-check text-white"></i>)}
                        </p>
                        <p className="step-name fw-bold" style={{ display: 'flex', justifyContent: 'center' }}>{item}</p>
                      </div>
                    ))}
                  </div>
                  <div className='table-responsive'>
                    <Table bordered>
                      <thead>
                        <tr className=''>
                          <th style={{ minWidth: 150 }} className='text-center bg-giga text-white'>Thời gian</th>
                          <th style={{ minWidth: 120 }} className='text-center bg-giga text-white'>Tác vụ</th>
                          <th className='text-center bg-giga text-white'>  Trạng thái</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          ttBaoHanh?.buocXuLyDon?.map((item, index) => (
                            <tr key={index}>
                              {/* <th scope="row" className='middle'> {moment(item.thoiGian).format('DD/MM/YYYY HH:mm')}</th> */}
                              <th scope="row" className='middle'>{item.thoiGian && (moment(item.thoiGian).format('DD/MM/YYYY HH:mm'))}</th>
                              <td className='middle'>{item.tacVu}</td>
                              <td className='middle' style={{ whiteSpace: 'pre-line' }}>  {Parser().parse(item.trangThai)}</td>
                            </tr>
                          ))
                        }
                      </tbody>
                    </Table>
                  </div>
                  {/* <ItemBaoHanh ttBaoHanh={ttBaoHanh}/> */}
                  <Modal title="Nhập định dạng file ảnh/video" open={isModalOpenVideo} width={1000} height={400} onOk={handleOkVideo} onCancel={handleCancelVideo}>
                    <div className="my-2">
                      <div className="file-upload-container">
                        <label for="file-custom" className="file-upload-label">
                          <div className="file-upload-content">
                          <svg className="file-upload-icon" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                            </svg>
                            <p className="file-upload-text-main">
                              <span className="file-upload-text-bold">Click to image or video</span> or drag and drop
                            </p>

                          </div>

                          <input id="file-custom" type="file" className="file-upload-input" onChange={onChangeFile} multiple />

                        </label>
                      </div>
                      <section className='uploaded-area'>
                        <div className='mt-2'>

                          {(listDetailFileVideo) && (
                            <>
                              {listDetailFileVideo?.map((file, index) => (
                                <>
                                  <li className="row col-md-12" style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                                    <div className="content upload col-md-9">
                                      <i className="fas fa-file-alt" />
                                      <div className="details">
                                        <span className="name">
                                          {file.name} • Uploaded
                                        </span>
                                        <span className="size">
                                          {file.size} KB
                                        </span>
                                      </div>
                                    </div>
                                    <div className="col-md-2" style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                                      <i className="fas fa-check" />
                                    </div>
                                  </li>
                                </>
                              ))}
                            </>
                          )}
                        </div>
                      </section>
                    </div>
                  </Modal>

                  <Modal title="Nhập phản hồi của khách hàng" open={isModalOpenFeedback} width={1000} onOk={handleOkFeedback} onCancel={handleCancelFeedback}>

                    <textarea name='feedback' style={{ width: '100%', height: '100px' }} onChange={onChangeNhapPhanHoi}>

                    </textarea>
                  </Modal>
                  <Modal open={isModalOpenDetailVideo} width={1000} height={400} onOk={handleOkDetailVideo} onCancel={handleCancelDetailVideo} footer={null}>
                    <div className="traccuu_detail_video my-2">
                      {listImage?.map((item) => (
                        <>
                          {(getTypeFile(item?.listFile) != "mp4") ? (
                            <div className='item-image'><img src={item?.listFile} onClick={() => openModalZoomInImage(item?.listFile)} alt="Paris" width="300" height="300" />  </div>
                          ) : (
                            <>
                              <video width="320" height="190" controls>
                                <source src={item?.listFile} type="video/mp4" />
                              </video>
                            </>
                          )}
                        </>
                      ))}

                    </div>
                  </Modal>
                  <Modal open={isModalOpenZoomInVideo} width={1000} onOk={handleOkZoomInVideo} onCancel={handleCancelZoomInVideo} footer={null} title={null}>

                    <div style={{ display: 'flex', justifyContent: 'center' }} className="traccuu_detail_video my-2 mt-3">
                      <img style={{ width: '100%' }} id='#imagezoomin' src={image} alt='Hinh anh' />

                    </div>
                  </Modal>
                  <Modal footer={null} open={isModalOpenViewFeedback} width={700} onOk={handleOkViewFeedback} onCancel={handleCancelViewFeedback}>
                    <div className='p-3 px-5'>
                      <h2 style={{ display: 'flex', justifyContent: 'center', color: '#479ae1' }}>THÔNG BÁO</h2>
                      {ttBaoHanh.ghiChuToiKhachHang ? (
                        <>
                          <div className='mt-3'>Nội dung thông báo</div>
                          <div name='feedback' style={{ width: '100%', minHeight: '40px', borderRadius: '10px', border: 'none', outline: 'none' }} className='px-2 mt-1'>
                            {ttBaoHanh.ghiChuToiKhachHang}
                          </div>
                          <label>Ngày cập nhật :</label>
                          <input style={{ border: 'none', outline: 'none' }} type='text' className='w-full' value={ttBaoHanh.ngayGhiChuToiKhachHang} /> <br />
                        </>
                      ) : (<div style={{ display: 'flex', justifyContent: 'center' }} className='mb-3'>
                        Chưa có thông báo gần đây
                      </div>)}
                      <div style={{ display: 'flex', justifyContent: 'center' }} className='flex justify-center'>
                        <button style={{ outline: 'none', border: 'none', backgroundColor: 'transparent', borderRadius: '10px', border: '1px solid #479ae1' }} className='p-2 px-5' onClick={() => setisModalOpenViewFeedback(false)}>Đóng</button>
                      </div>
                    </div>
                  </Modal>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>


  )
}

export default TraCuuDetail

